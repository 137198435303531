import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Flex, Box } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function PrivacyModal({ openModal = false, closeModal, scrollToGrievanceOfficer = false }) {
  const [show, setShow] = useState(openModal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShow(openModal);
  }, [openModal]);

  const navigate = useNavigate();

  // const handleClose = () => closeModal();
  const handleClose = () => {
    closeModal();
    navigate(window.location.pathname);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header style={{ justifyContent: "right" }}>
          <Modal.Title> {t("FOOTER.PRIVACY")} </Modal.Title>

          <Flex
            align="center"
            justify="center"
            borderRadius="50%"
            width="30px"
            height="30px"
            backgroundColor="#008cf0"
            color="white"
            onClick={handleClose}
            cursor="pointer"
            position="sticky">
            <Box as={AiOutlineClose} color="white" />
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "0px" }}>
          {i18n.language === "en" ? (
            <ModalContentEnglish scrollToGrievanceOfficer={scrollToGrievanceOfficer} />
          ) : (
            <ModalContentHindi scrollToGrievanceOfficer={scrollToGrievanceOfficer} />
          )}
        </Modal.Body>
        <Modal.Footer px={"20px"} bg="#f0f0f0">
          <div className="text-center w-100">
            <button className="mx-auto blueBtn " onClick={handleClose}>
              {t("FOOTER.MODAL_OK")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const ModalContentEnglish = ({ scrollToGrievanceOfficer = false }) => {
  const queriesRef = useRef();
  useEffect(() => {
    if (scrollToGrievanceOfficer) {
      setTimeout(() => {
        queriesRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [scrollToGrievanceOfficer]);

  return (
    <div className="privacyModalContent">
      <div style={{ textAlign: "justify", paddingRight: "1.25rem" }}>
        <p>
          <i>
            Game of Choice, Not Chance<sup>TM</sup>
          </i>{" "}
          is a branded digital platform using play to empower the choices and voices of young people. The first product
          offered by said platform is ‘Go Nisha Go’, a game app and its connected microsite, which are owned by Howard
          Delafield International (‘HDI’, we, our or us), a behavioural insights consultancy. HDI is committed to
          respecting the privacy and security of those visiting or accessing the app and the microsite.
        </p>

        <p>
          This Privacy Policy explains how and what information will be collected, used and disclosed through the Go
          Nisha Go game app and microsite (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ) collectively referred to as “Services”.
        </p>

        <p>
          This Privacy Policy applies only to the Services where you, the user, are accessing this policy. This Privacy
          Policy also applies to any offline location that makes this Privacy Policy available to you. This Privacy
          Policy does not apply to the collection, use or disclosure of information through any apps or websites linked
          from within the Services for which HDI is not the publisher or any information collected offline or via
          channels other than these Services.
        </p>

        <p style={{ fontWeight: 700, paddingTop: "20px", paddingTop: "20px" }}>WHAT INFORMATION IS COLLECTED AND HOW</p>

        <ol style={{ paddingLeft: "2rem" }}>
          <li>
            The Services do not collect or share any personal information. Any non-personal information that is sought
            is used for non-commercial purposes only. There are no free text input areas within the Services through
            which the user can provide us with any personal information. The details of any non-personal information
            that you are asked to provide are only for purposes of research.
          </li>

          <li>
            If you contact us directly, we may receive additional information about you such as your name, email
            address, phone number, the contents of the message and/or attachments you may send us and any other
            information you may choose to provide. Any such information shall only be used to communicate with you and
            will not be forwarded to any third parties.
          </li>

          <li>
            Besides this, certain data is automatically gathered and stored in log files, which are files that record
            events that occur in connection with your use of the Services. This includes information regarding your
            browser type, referring/exit pages, date/time stamp and clickstream data. Clickstream data is a log of the
            content which the user clicks while using our Services and other actions taken on our Services. All of this
            data is tagged against a unique identifier for the user’s device/user profile. The unique identifier is an
            alphanumeric code that is autogenerated by the system. None of this data is linked to any information that
            is personally identifiable.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>HOW THE INFORMATION IS USED</p>

        <ol style={{ paddingLeft: "2rem" }} start={4}>
          <li>
            The non-personal data collected through our Services is used in various ways, including to:
            <ul>
              <li>track trends, user preferences and movements on our Services;</li>
              <li>gather aggregated demographic information of our users;</li>
              <li>provide, operate, and maintain our Services;</li>
              <li>improve, personalize, and expand our Services;</li>
              <li>develop new products, services, features, and functionality;</li>
              <li>health-related research and analysis.</li>
            </ul>
          </li>

          <li>
            Aggregated data might be shared with funders and related vendors/partners for research and analysis, or to
            provide extra services (such as coupons) to users to optimize their education in health.
          </li>

          <li> No personal information of the user is collected or shared with any third parties.</li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>HOW THE INFORMATION IS STORED AND PROTECTED</p>

        <ol style={{ paddingLeft: "2rem" }} start={7}>
          <li>
            Data collected through our Services is stored on servers that are leased by HDI, located in India. Access to
            these servers is limited to authorized personnel from HDI. HDI manages and monitors access and security of
            these servers. We make our best effort to ensure security on our systems and take reasonable steps to
            protect our Services and data collected from theft, alteration or unauthorised inspection.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>USE OF COOKIES AND TRACKING</p>

        <ol style={{ paddingLeft: "2rem" }} start={8}>
          <li>
            We do not use cookies to collect information which might identify you personally on our Services. Our
            Microsite, however, does use Google Analytics, to track the users that visit the Microsite and to help us
            analyse the use of our Services, compile statistical reports on the Services’ activity and provide us with
            other services relating to internet usage etc. Google will collect certain information to be used according
            to Google’s privacy practices.
          </li>

          <li>We do not engage in behavioural advertising on our Services.</li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>AGE VERIFICATION</p>

        <ol style={{ paddingLeft: "2rem" }} start={10}>
          <li>
            We do not knowingly allow individuals under the age of 15 to use the Go Nisha Go game app. The Go Nisha Go
            game app is designed to cater to individuals who are aged 15 years and above. By downloading the Go Nisha Go
            game app, you confirm that you are aged 15 years or above and have agreed to the Terms of Service of the
            Game.
          </li>

          <li>
            In the Game, levels 1 and 2 deal with negotiation and menstruation. Levels 3, 4 and 5 of the Game deal with
            content related to gender-based violence (GBV) and consent, fertility awareness, contraception and delaying
            early marriage, and are provided with the purpose of improving knowledge on sexual and reproductive health
            amongst adolescents and youth only. Parental guidance is essential for users who are under 18 years. We
            encourage users below 18 years of age to seek parental guidance and obtain parental consent for downloading
            the game. If you have any questions, and for further support, you may contact us at &nbsp;
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            .
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>THIRD-PARTY LINKS</p>

        <ol style={{ paddingLeft: "2rem" }} start={12}>
          <li>
            The Go Nisha Go game app contains links to other partner sites that are not under our control. These partner
            sites do not follow our privacy policy, and we recommend that you read their privacy policies carefully.
          </li>

          <li>
            Any links to third-party software available on our Services are provided “as is” without warranty of any
            kind, either expressed or implied, and access to such links and/or products/information/services available
            on these links is at the user’s own risk.
          </li>

          <li>
            These third-party partners' websites on the Go Nisha Go game app may provide various information, products
            and/or services. We will not be responsible or liable for the content and activities of these sites, or for
            any damages that are caused by or in connection with access to these links.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>CONSENT</p>

        <ol style={{ paddingLeft: "2rem" }} start={15}>
          <li>
            By using our Services, you hereby consent to our Privacy Policy, Disclaimers, Copyright Notice and Terms of
            Service.
          </li>

          <li>
            By using the Services or interacting, online or offline, you provide your consent to our Privacy Policy, the
            collection, use and disclosure practices, and other activities described therein. If you do not agree,
            please discontinue any use of our Services.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>CHANGES TO THE PRIVACY POLICY</p>

        <ol style={{ paddingLeft: "2rem" }} start={17}>
          <li>
            We reserve the right to make changes to this Privacy Policy at any time. Any changes will be effective
            immediately upon posting of the revised Privacy Policy. Please check the Privacy Policy each time you use
            our Services to ensure you are aware of any changes in our privacy practices. Our Privacy Policy will
            indicate the date it was last updated. Your continued use of our Services will signify your acceptance of
            the changes to our Privacy Policy.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }} id="queriesAndFeedback" ref={queriesRef}>
          QUERIES AND FEEDBACK
        </p>

        <ol style={{ paddingLeft: "2rem" }} start={18} className={scrollToGrievanceOfficer ? "highlight" : ""}>
          <li>
            If you have any questions or concerns relating to our Privacy Policy or how we capture, store or use
            information or regarding deletion of game data, please contact us at &nbsp;
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            .
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>
          Last modified on <u>31 May 2022</u>
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

const ModalContentHindi = ({ scrollToGrievanceOfficer = false }) => {
  const queriesRef = useRef();
  useEffect(() => {
    if (scrollToGrievanceOfficer) {
      setTimeout(() => {
        queriesRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [scrollToGrievanceOfficer]);

  return (
    <div className="privacyModalContent">
      <div style={{ textAlign: "justify", paddingRight: "1.25rem" }}>
        <p>
          <i>
            Game of Choice, Not Chance<sup>TM</sup>
          </i>{" "}
          एक ब्रांडेड डिज़िटल प्लेटफ़ॉर्म है जो नाटकों का उपयोग करते हुए, युवाओं के विकल्पों और उनकी आवाज़ को सशक्त बनाता
          है। इस प्लेटफ़ॉर्म द्वारा प्रस्तुत पहला उत्पाद ‘Go Nisha Go’ है, जो कि एक गेम ऐप है और इससे संबंधित माइक्रोसाइट
          है, जिसका स्वामित्व Howard Delafield International (‘HDI’, हम, हमारे या हमें) के पास है, जो कि एक व्यवहारगत
          अंतर्दृष्टि परामर्श (बिहेविरल इनसाइट्स कंसल्टैंसी) कंपनी है। ऐप और माइक्रोसाइट पर विजिट करने वाले या पहुंचने
          वाले लोगों की गोपनीयता और सुरक्षा बनाए रखने के लिए HDI प्रतिबद्ध है।
        </p>

        <p>
          इस गोपनीयता नीति में यह स्पष्ट किया गया है कि Go Nisha Go गेम ऐप और माइक्रोसाइट (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ) जिसे कि सामूहिक रूप से “सेवाएं” कहा गया है, के माध्यम से किस प्रकार से, और कौन-सी जानकारी संकलित, उपयोग और
          प्रकट की जाएगी।
        </p>

        <p>
          यह गोपनीयता नीति केवल उन सेवाओं पर लागू है जहां उपयोगकर्ता के रूप में आप इस नीति का अभिगमन कर रहे हैं। यह
          गोपनीयता नीति किसी ऐसी ऑफ़लाइन लोकेशन पर भी लागू है जहां आपके लिए यह गोपनीयता नीति उपलब्ध हो। यह गोपनीयता नीति
          उन सेवाओं में से संबंधित किसी ऐप या वेबसाइटों के माध्यम से जानकारी के संकलन, उपयोग या प्रकटीकरण पर लागू नहीं
          है, जिसके HDI प्रकाशक नहीं हों, या कोई जानकारी ऑफ़लाइन, या इन सेवाओं के अलावा अन्य चैनलों के माध्यम से संकलित
          की गई हो।
        </p>

        <p style={{ fontWeight: 700, paddingTop: "20px", paddingTop: "20px" }}>
          कौन-सी जानकारी और किस प्रकार संकलित की जाती है
        </p>

        <ol style={{ paddingLeft: "2rem" }}>
          <li>
            सेवाएँ कोई निजी जानकारी संकलित या साझा नहीं करतीं। वांछित किसी गैर-निजी जानकारी का केवल गैर-व्यावसायिक
            उद्देश्यों के लिए उपयोग किया जाएगा। सेवाओं में टेक्स्ट के लिए ऐसा कोई स्वतंत्र इनपुट क्षेत्र नहीं है जिसके
            माध्यम से उपयोगकर्ता हमें कोई निजी जानकारी प्रदान कर सकते हों। आपसे कोई गैर-निजी जानकारी केवल अनुसंधान के
            प्रयोजन से मांगी जाएगी।
          </li>

          <li>
            यदि आप हमसे सीधे संपर्क करते हैं, तो हम आपके बारे में अतिरिक्त जानकारी जैसे आपका नाम, ईमेल पता, फोन नंबर,
            संदेश की सामग्री और/या संलग्नक जो आप हमें भेज सकते हैं और कोई अन्य जानकारी जो आप दे सकते हैं, आदि प्राप्त कर
            सकते हैं। ऐसी किसी जानकारी का केवल आपके साथ संवाद करने के लिए ही उपयोग किया जाएगा और यह किसी तीसरे पक्ष को
            नहीं भेजी जाएगी।
          </li>

          <li>
            इसके अलावा, कुछ डेटा लॉग फ़ाइलों में स्वतः एकत्रित और संग्रहीत होता है, जो कि ऐसी फ़ाइलें होती हैं जो आप
            द्वारा सेवाओं के उपयोग के संबंध में होने वाली घटनाओं को रिकॉर्ड करती हैं। इसमें आपके ब्राउज़र प्रकार,
            रेफ़रिंग/एक्जिट पेज, दिनांक/समय स्टाम्प और Clickstream डेटा के बारे में जानकारी शामिल है। Clickstream डेटा
            उस सामग्री का एक लॉग है जिसे उपयोगकर्ता हमारी सेवाओं का उपयोग करते समय और हमारी सेवाओं पर की गई अन्य
            कार्यवाहियों के समय क्लिक करता है। यह सारा डेटा उपयोगकर्ता की डिवाइस/उपयोगकर्ता प्रोफ़ाइल के लिए एक विशिष्ट
            पहचानकर्ता के सापेक्ष टैग किया जाता है। विशिष्ट पहचानकर्ता, अक्षरों और संख्याओं वाला एक कोड होता है जो
            सिस्टम द्वारा स्वतः बनाया जाता है। इसमें से कोई भी डेटा, निजी पहचान योग्य किसी जानकारी से जुड़ा नहीं होता।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>जानकारी का उपयोग कैसे किया जाता है</p>

        <ol style={{ paddingLeft: "2rem" }} start={4}>
          <li>
            हमारी सेवाओं के माध्यम से संकलित किए गए गैर-निजी डेटा का उपयोग विभिन्न प्रकार से किया जाता है, जिसमें निम्न
            शामिल हैं:
            <ul>
              <li>हमारी सेवाओं के बारे में रूझानों, उपयोगकर्ता की वरीयताओं और गतिविधियों पर निगाह रखना;</li>
              <li>हमारे उपयोगकर्ताओं की संकलित जनसांख्यिकीय जानकारी प्राप्त करना;</li>
              <li>हमारी सेवाएं प्रदान करना, संचालन करना और रखरखाव करना;</li>
              <li>हमारी सेवाओं में सुधार करना, निजी बनाना और विस्तार करना;</li>
              <li>नए उत्पादों, सेवाओं, विशेषताओं और कार्यक्षमताओं का विकास करना;</li>
              <li>स्वास्थ्य संबंधी अनुसंधान और विश्लेषण।</li>
            </ul>
          </li>

          <li>
            अनुसंधान और विश्लेषण के लिए, या उपयोगकर्ताओं की स्वास्थ्य संबंधी शिक्षा अनुकूलित बनाने हेतु अतिरिक्त सेवाएं
            (जैसे कि कूपन) प्रदान करने के लिए, संकलित डेटा को वित्तपोषकों और संबंधित वेंडरों/साझेदारों के साथ साझा किया
            जा सकता है।
          </li>

          <li>उपयोगकर्ता की कोई निजी जानकारी संकलित या किसी तीसरे पक्ष के साथ साझा नहीं की जाती।</li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>जानकारी को किस प्रकार संग्रहीत और संरक्षित किया जाता है</p>

        <ol style={{ paddingLeft: "2rem" }} start={7}>
          <li>
            हमारी सेवाओं के माध्यम से संकलित किया गया डेटा, HDI के लीज़ आधारित सर्वरों पर संग्रहीत किया जाता है जो भारत
            में स्थित हैं। इन सर्वरों तक पहुंच HDI के अधिकृत कर्मचारियों तक ही सीमित है। HDI इन सर्वरों तक पहुंच और
            सुरक्षा का प्रबंधन और निगरानी करता है। हम अपने सिस्टमों पर सुरक्षा सुनिश्चित करने के लिए सर्वोत्तम प्रयास
            करते हैं और हमारी संकलित सेवाओं और डेटा को चोरी, छेड़छाड़, या अनधिकृत तौर पर देखे जाने से सुरक्षित रखने के लिए
            उचित कदम उठाते हैं।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>कुकीज़ का उपयोग और ट्रैकिंग</p>

        <ol style={{ paddingLeft: "2rem" }} start={8}>
          <li>
            हम जानकारी संकलित करने के लिए ऐसी कुकीज़ का उपयोग नहीं करते हैं जो हमारी सेवाओं पर आपकी निजी पहचान कर सकती
            हों। हालाँकि, हमारी माइक्रोसाइट पर Google Analytics का प्रयोग किया जाता है ताकि माइक्रोसाइट पर विजिट करने
            वाले उपयोगकर्ताओं को ट्रैक करने, और हमारी सेवाओं के उपयोग का विश्लेषण करने, सेवाओं की गतिविधि के बारे में
            सांख्यिकीय रिपोर्ट संकलित करने और हमें इंटरनेट उपयोग आदि से संबंधित अन्य सेवाएं प्रदान करने में मदद मिल सके।
            उपयोग की जाने वाली कुछ निश्चित जानकारी को Google द्वारा Google की गोपनीयता विधियों के अनुसार संकलित किया
            जाएगा।
          </li>

          <li>हम अपनी सेवाओं के बारे में व्यवहारगत विज्ञापनों में सम्मिलित नहीं होते हैं।</li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>आयु का सत्यापन</p>

        <ol style={{ paddingLeft: "2rem" }} start={10}>
          <li>
            हम जानबूझकर 15 वर्ष से कम आयु के व्यक्तियों को Go Nisha Go गेम ऐप का उपयोग करने की अनुमति नहीं प्रदान करते
            हैं। Go Nisha Go गेम ऐप उन लोगों के लिए बनाया गया है जिनकी आयु 15 वर्ष और इससे अधिक है। Go Nisha Go गेम ऐप
            डाउनलोड करके, आप इसकी पुष्टि करते हैं कि आपकी आयु 15 वर्ष या अधिक है और आप इस गेम की सेवा की शर्तों से सहमत
            हैं।
          </li>

          <li>
            गेम में, लेवल 1 और 2 नेगोशिएशन (बातचीत) और मासिक धर्म से संबंधित हैं। गेम के लेवल 3, 4 और 5 जेंडर आधारित
            हिंसा (GBV) और सहमति, प्रजनन संबंधी जागरूकता, गर्भनिरोध और बाल विवाह टालने से संबंधित सामग्री के बारे में
            हैं और किशोरों तथा युवाओं के यौन और प्रजनन स्वास्थ्य संबंधी ज्ञान में सुधार करना ही इनका उद्देश्य है। 18
            वर्ष से कम आयु के उपयोगकर्ताओं के लिए माता-पिता का मार्गदर्शन आवश्यक है। हम 18 वर्ष से कम आयु के
            उपयोगकर्ताओं को प्रोत्साहित करते हैं कि वे माता-पिता का मार्गदर्शन लें और माता-पिता की सहमति लेकर ही गेम
            डाउनलोड करें। आप अपने किन्हीं प्रश्नों के समाधान के लिए, तथा अन्य सहायता के लिए, हमसे &nbsp;
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            &nbsp; पर संपर्क कर सकते हैं।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>तीसरी पार्टी के लिंक</p>

        <ol style={{ paddingLeft: "2rem" }} start={12}>
          <li>
            Go Nisha Go गेम ऐप में अन्य पार्टनर साइटों के लिंक हैं जो हमारे नियंत्रण में नहीं हैं। ये पार्टनर साइटें,
            हमारी गोपनीयता नीति का पालन नहीं करतीं, और हमारी सलाह है कि आप उनकी गोपनीयता नीति को सावधानी से पढ़ लें।
          </li>

          <li>
            हमारी सेवाओं पर किसी तीसरी पार्टी वाले सॉफ्टवेयर के लिए कोई उपलब्ध लिंक, किसी प्रकार की स्पष्ट या निहित
            वारंटी के बिना “जैसा है” के आधार पर उपलब्ध कराया गया है और ऐसे लिंकों और/या इन लिंकों पर उपलब्ध
            उत्पादों/सूचनाओं/सेवाओं तक पहुंच उपयोगकर्ता के अपने खुद के जोखिम पर होगी।
          </li>

          <li>
            Go Nisha Go गेम ऐप पर इन तीसरी पार्टी वाले पार्टनर्स की वेबसाइटें, विविध जानकारी/उत्पाद और/या सेवाएं प्रदान
            कर सकती हैं। इन साइटों की विषयवस्तु और गतिविधियों, तथा इन लिंकों के कारण या इन तक पहुंच के संदर्भ में
            उत्पन्न होने वाली किसी क्षति के लिए हम जिम्मेदार नहीं होंगे।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>सहमति</p>

        <ol style={{ paddingLeft: "2rem" }} start={15}>
          <li>
            हमारी सेवाओं का उपयोग करते हुए, आप एतद्द्वारा हमारी गोपनीयता नीति, अस्वीकरण, कॉपीराइट नोटिस और सेवा की
            शर्तों के प्रति सहमति प्रदान करते हैं।
          </li>

          <li>
            सेवाओं का उपयोग करके या ऑनलाइन या ऑफ़लाइन अंतर्क्रिया करते हुए आप हमारी गोपनीयता नीति, संकलन, उपयोग और
            प्रकटीकरण की विधियों और उसमें वर्णित अन्य क्रियाकलापों के लिए अपनी सहमति प्रदान करते हैं। यदि आप सहमत नहीं
            हैं, तो कृपया हमारी सेवाओं का उपयोग न करें।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>गोपनीयता नीति में परिवर्तन</p>

        <ol style={{ paddingLeft: "2rem" }} start={17}>
          <li>
            इस गोपनीयता नीति में किसी भी समय कोई परिवर्तन करने का अधिकार हमारे पास सुरक्षित है। कोई भी परिवर्तन,
            पुनरीक्षित गोपनीयता नीति पोस्ट करने के तुरंत पश्चात से प्रभावी होगा। हमारी गोपनीयता विधियों में किसी
            परिवर्तन से अवगत होना सुनिश्चित करने के लिए कृपया हमारी सेवाओं का उपयोग करते समय प्रत्येक बार गोपनीयता नीति
            की जाँच करें। हमारी गोपनीयता नीति पर उसे पिछली बार अपडेट करने की तारीख दी जाएगी। आप द्वारा हमारी सेवाओं का
            निरंतर उपयोग करना, हमारी गोपनीयता नीति में परिवर्तनों के प्रति आपकी स्वीकृति दिखाता है।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }} id="queriesAndFeedback" ref={queriesRef}>
          प्रश्न और प्रतिक्रियाएं
        </p>

        <ol style={{ paddingLeft: "2rem" }} start={18} className={scrollToGrievanceOfficer ? "highlight" : ""}>
          <li>
            हमारी गोपनीयता नीति के बारे में या हमारे द्वारा जानकारी को एकत्रित, भंडारित या उपयोग किए जाने के बारे में,
            या गेम डेटा को हटाने के बारे में आपका कोई प्रश्न या चिंता होने पर, कृपया हमसे &nbsp;
            <br />
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            <br />
            पर संपर्क करें।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>
          अंतिम बार <u> 31 मई 2022</u> को संशोधित किया गया
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
